<template>
  <b-dropdown
    size="md"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
    v-b-tooltip.hover.top="'Edit status'"
  >
    <template v-slot:button-content>
      <i class="flaticon2-settings"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" disabled>
          <!-- <span class="navi-icon">
            <i class="flaticon2-settings"></i>
          </span> -->
          <span class="navi-text">Change status for entire <br /><b>Conveyor {{ conveyorNumber }}</b> now it's {{ newstatus }}</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-separator my-3"></b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" style="cursor: pointer;" @click="submit('green')">
          <span class="navi-icon">
            <i class="flaticon2-check-mark text-success"></i>
          </span>
          <span class="navi-text">Just changed</span>
          <span class="navi-link-badge">
            <span
              class="label label-light-success label-inline font-weight-bold"
            >
              green
            </span>
          </span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" style="cursor: pointer;" @click="submit('orange')">
          <span class="navi-icon">
            <i class="flaticon2-warning text-warning"></i>
          </span>
          <span class="navi-text">Be carefull</span>
          <span class="navi-link-badge">
            <span
              class="label label-light-warning label-inline font-weight-bold"
            >
              orange
            </span>
          </span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" style="cursor: pointer;" @click="submit('red')">
          <span class="navi-icon">
            <i class="flaticon-cancel text-danger"></i>
          </span>
          <span class="navi-text">Must change</span>
          <span class="navi-link-badge">
            <span
              class="label label-light-danger label-inline font-weight-bold"
            >
              red
            </span>
          </span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import axios from 'axios';
export default {
  props: ['conveyorNumber', 'companyId', 'lineNumber'],
  name: "dropdown-3",
  data() {
    return {
      submitted: false,
      newstatus: "unchanged",
    };
  },
  components: {},
  mounted() {},
  methods: {
    // submit form handler
    submit: function(code) {
      this.submitted = true;
      this.newstatus = code;
      // Execute Update Conveyor Status
      axios.post('line/updateConveyorStatus', {CompanyId: this.companyId, LineNumber: this.lineNumber, ConveyorNumber: this.conveyorNumber, NewStatus: this.newstatus})
      .then(() => {
        // Reload page with new status
        this.$emit('statusChange')
      }).catch(err => {
        throw err;
      })

    }
  }
};
</script>
